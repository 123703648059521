<i18n src="@/i18n/user.json"></i18n>

<template lang="pug">
    .user-verification.view
        .content-wrapper
            .header
                h2 {{ $t('user.verification.title') }}
            .content
                el-form(:model="verification_form", :disabled="verification_form_disabled", :rules="verification_form_rules", @keydown.enter.native.prevent="sendVerificationForm", ref="verification_form", label-position="top", autocomplete="off")
                    el-form-item(prop="email", :label="$t('user.form.email')")
                        el-input(v-model="verification_form.email", type="email")
                    el-form-item
                        el-button(@click="sendVerificationMail", type="primary") {{ $t('user.verification.button') }}
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { SEND_VERIFICATION_MAIL } from "@/store/types"

    const default_verification_form = ()=>{
        return {
            email: '',
        }
    }

    export default {
        data () {
            return {
                verification_form_disabled: false,
                verification_form: default_verification_form(),
                verification_form_rules: {
                    email: [
                        { required: true, message: this.$t('user.form.validation.email.required'), trigger: 'blur' },
                        { type: 'email', message: this.$t('user.form.validation.email.email'), trigger: 'blur' },
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                'auth_user'
            ]),
        },
        mounted() {
            if(this.auth_user.loggedIn()){
                // Redirect if has already verified
                if(this.auth_user.emailVerified()) this.$router.push({ name: 'index' })

                this.setVerificationForm()
            }else{
                // Redirect if not logged in
                this.$router.push({ name: 'user.login' })
            }
        },
        methods: {
            ...mapActions([
                SEND_VERIFICATION_MAIL
            ]),
            // Merge user data
            setVerificationForm()
            {
                this.verification_form.email = this.auth_user.email
            },
            // Execute send verification mail action
            sendVerificationMail()
            {
                this.$refs['verification_form'].validate((valid) => {
                    if (valid) {
                        this.SEND_VERIFICATION_MAIL(this.verification_form).then(()=>{
                            // Disable verification form
                            this.verification_form_disabled = true

                            this.$root.pushAppMessages(this.$t('user.verification.messages.sent'), 'success')
                        }).catch((error)=>{
                            this.$root.pushAppMessages(error.response.data)
                        }).finally(()=>{
                            // Enable verification form
                            this.verification_form_disabled = false
                        })
                    } else return false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-verification{
        .el-form{
            width: 50%;
        }
}
</style>
